@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:ital,wght@0,100..800;1,100..800&display=swap');

body {
  margin: 0;
  font-family: 'JetBrains Mono';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-size: calc(10px + 1.4vmin);
  color: #DBE6EE;

  font-weight: 200;


  @media (max-width: 459px) {
    & {
      font-size: calc(10px + 2vmin);
    }
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
