.App {
    text-align: center;
}

.section:nth-child(odd) {
    background:
        url(/assets/dots.png) 0% 0% / 50px 50px repeat,
        linear-gradient(315deg, #0E0E0E 0%, #0E0E0E 100%),
        #1A1A1A;
    background-blend-mode: overlay, darken;
}

.section:nth-child(even) {
    background:
        url(/assets/dots.png) 0% 0% / 50px 50px repeat,
        linear-gradient(225deg, #0E0E0E 0%, #0E0E0E 100%),
        #1A1A1A;;
    background-blend-mode: overlay, darken;
}

.first {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.yellow {
    color: #F1BD01
}

h1 {
    text-transform: uppercase;
}

.fp-watermark a {
    background-color: rgba($color: #000000, $alpha: 0) !important;
    color: #F1BD01 !important;
    font-family: 'JetBrains Mono' !important;
    font-weight: 700 !important;
}