#fusion-it {
    p {
        margin: 2em 6em;

        &.technologies  {
            & svg {
                height: 50px;
                width: auto;
                margin-inline: 1em;
            }
        }
    }
    a > svg {
        margin-top: 10px;
        height: 5em;
        width: 5em;
        stroke: #fff;
        fill: #fff;

        &:hover {
            border-radius: 10px;
            background: #fff;
            // stroke: #a0a0a0;
            // fill: #a0a0a0;
            stroke: #0077B5;
            fill: #0077B5;
        }
    }

    h4 {
        text-transform: uppercase;
    }
}