.center {
    position: relative;
    object-fit: contain;
    max-width: 90%;
    max-height: 90%;
    width: auto;
    height: auto;
    display: block;


    & path {
        stroke: #FFC700;
        /* fill: #675f1f; */
        fill: none;
        stroke-width: 20;
        

        stroke-linecap: round;
        stroke-miterlimit: 10;

        animation: dash 4s 1 linear forwards;

        &:nth-child(1) {
            stroke-dasharray: 36400;
            stroke-dashoffset: 36400;
            
        }            
        &:nth-child(2) {
            animation-delay: 1s;
            stroke-dasharray: 29500;
            stroke-dashoffset: 29500;
        }
        &:nth-child(3) {
            animation-duration: 0.2s;
            animation-delay: 4.5s;
            stroke-dasharray: 470;
            stroke-dashoffset: 470;
        }            
        &:nth-child(4) {
            animation-duration: 1s;
            animation-delay: 3.5s;
            stroke-dasharray: 190;
            stroke-dashoffset: 190;
        }            
        &:nth-child(5) {
            animation-duration: 1s;
            animation-delay: 3.5s;
            stroke-dasharray: 2270;
            stroke-dashoffset: 2270;
        }
        &:nth-child(6) {
            animation-duration: 1s;
            animation-delay: 3.5s;
            stroke-dasharray: 1860;
            stroke-dashoffset: 1860;
        }
        &:nth-child(7) {
            animation-duration: 1s;
            animation-delay: 3.5s;
            stroke-dasharray: 1310;
            stroke-dashoffset: 1310;
        }
        &:nth-child(8) {
            animation-duration: 0.2s;
            animation-delay: 5.1s;
            stroke-dasharray: 25590;
            stroke-dashoffset: 25590;
        }
        &:nth-child(9) {
            animation-duration: 0.2s;
            animation-delay: 5.1s;
            stroke-dasharray: 3720;
            stroke-dashoffset: 3720;
        }
    }
}

@keyframes dash {
    100% {
        stroke-dashoffset: 0;
        /* fill: #675f1f; */
        /* stroke: none; */
    }
}