#healthcare-it {
    .wrapper {
        margin: 0 6em;

        
        & > * {
            margin: 0;
            text-align: left;

            &:has(img) {
                text-align: center;
            }
            
            & img {
                max-height: 80vh;
                max-width: 100%;
                object-fit: contain;
                border: 3px solid #FFF;
                box-shadow: 20px 20px 0px 0px #F1BD01;
            }
        }

        @media (min-width: 804px)  {
            display: grid;
            // grid-template-columns: repeat(auto-fit, minmax(210px, 1fr));
            grid-template-columns: 1fr 3fr;
            grid-gap: 4em;

            & * {
                & h1 {
                    margin-block-start: 0;
                }
            }
        }
        
        @media (max-width: 803px) {
            display: flex;
            flex-direction: column;
            gap: 1em;
            margin-bottom: 4em;

            & * {
                & img {
                    margin-top: 2em;
                    height: 100%;
                }
            }
        }
    }
}