.scroll-down-button {
    cursor: pointer;
    position: absolute;
    bottom: 5vh;
    right: 5vh;
    color: #868686;

    & svg {
        stroke: #868686;
    }

    animation: blinker 1s linear infinite;
}

@keyframes blinker {
    50% {
      opacity: 0.6;
    }
  }
  